<template>
  <div class="container mt-1">
    <b-col lg="12" sm="12" class="mb-2" v-if="show_alert_get_thumbnails">
      <b-alert
        show
        dismissible
        fade
        class="mb-0"
        variant="warning"
      >
        <div class="alert-body">
          <span>{{
            $t(
              "Pages.Studio.Title.wait_receciving_comments"
            )
          }}</span>
        </div>
      </b-alert>
    </b-col>
    <b-card v-if="!show_alert_get_thumbnails" v-for="item in this.allComments">
      <div class="row">
        <div class="col-8">
          <div class="card-body d-flex">
            <div class="mr-3">
              <img
                size="70"
                :src="item.creator_avatar"
                style="width: 50px; height: 50px; border-radius: 50%"
              />
            </div>
            <div>
              <span class="weight-bold">{{ item.creator_name }}</span>
              <div>
                <span class="">{{
                  new Date(item.created_at)
                    .toLocaleString("en-US", {
                      timeZone: "UTC",
                      hour12: false,
                      year: "numeric",
                      month: "2-digit",
                      day: "2-digit",
                      hour: "2-digit",
                      minute: "2-digit",
                      second: "2-digit",
                    })
                    .replace(",", "")
                }}</span>
              </div>

              <p style="margin: 20px 0%">{{ item.text }}</p>
              <span
                class="comment-counter"
                @click.prevent="toggleShowReplies(item)"
              >
                <span class="count-number">{{ item.total_reply_count }}</span>
                <feather-icon
                  icon="MessageSquareIcon"
                  size="20"
                  class="cursor-pointer"
                />
              </span>
              <feather-icon
                icon="EditIcon"
                size="20"
                class="ml-1 cursor-pointer"
                @click="showEditCommentTextSection(item)"
              />
              <feather-icon
                icon="TrashIcon"
                size="20"
                class="ml-1 cursor-pointer"
                @click="deleteComment(item)"
              />
              <a
                href="#"
                class="ml-2"
                @click.prevent="toggleReplayToComment(item)"
                >{{ $t("Pages.Studio.Button.Reply") }}</a
              >
              <div v-if="item.showEditCommentSection" class="mb-1">
                <div class="w-80">
                  <textarea
                    class="reply-area"
                    style="display: block; margin-top: 30px"
                    v-model="item.editedCommentText"
                  ></textarea>
                  <div class="saveOrCancel mt-1">
                    <a
                      href="#"
                      class="mr-1"
                      @click.prevent="updateEditedComment(item)"
                      >{{ $t("Pages.Studio.Button.Update") }}</a
                    >
                    <a href="#" @click.prevent="cancelEditComment(item)">
                      {{ $t("Pages.Studio.Button.Cancel") }}
                    </a>
                  </div>
                </div>
              </div>
              <div v-if="item.replayToComment">
                <div class="w-80">
                  <textarea
                    class="reply-area"
                    style="display: block; margin-top: 30px"
                    rows="8"
                    v-model="commentReplyText"
                  ></textarea>
                  <div class="saveOrCancel mt-1">
                    <a
                      href="#"
                      class="mr-1"
                      @click.prevent="insertCommentReply(item)"
                      >{{ $t("Pages.Studio.Button.Submit") }}</a
                    >
                    <a href="#" @click.prevent="cancelReply(item)">
                      {{ $t("Pages.Studio.Button.Cancel") }}
                    </a>
                  </div>
                </div>
              </div>
              <div class="mt-2" v-if="item.showCommentReplies">
                <div
                  v-if="currentItemReplies == item.comment_id"
                  v-for="replyItem in allCommentReplies"
                >
                  <div class="card-body d-flex">
                    <div class="mr-3">
                      <img
                        size="70"
                        :src="replyItem.creator_avatar"
                        style="width: 30px; height: 30px; border-radius: 50%"
                      />
                    </div>
                    <div>
                      <div>
                        <span class="weight-bold">{{
                          replyItem.creator_name
                        }}</span>
                      </div>

                      <p style="margin: 20px 0%">{{ replyItem.text }}</p>

                      <a
                        href="#"
                        class="ml-1"
                        @click.prevent="toggleReplyToCommentReply(replyItem)"
                        >پاسخ</a
                      >
                      <textarea
                        style="display: block; margin-top: 30px"
                        rows="2"
                        cols="60"
                        class="mb-1"
                        v-if="replyItem.showReplyToCommentReplyTextbox"
                        v-model="replyItem.replyToCommentReplyText"
                      >
                      </textarea>
                      <a
                        href="#"
                        class="mr-1"
                        v-if="replyItem.showReplyToCommentReplyTextbox"
                        @click.prevent="insertReplyToCommentReply(replyItem)"
                        >{{ $t("Pages.Studio.Button.Submit") }}</a
                      >
                      <a
                        href="#"
                        v-if="replyItem.showReplyToCommentReplyTextbox"
                        @click.prevent="cancelReplyToCommentReply(replyItem)"
                      >
                        {{ $t("Pages.Studio.Button.Cancel") }}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-4 mt-2">
          <b-row v-for="(avatar, index) in avatarDetailsArray">
            <b-col cols="auto" v-if="avatar.id.videoId == item.video_id">
              <img
                :src="avatar.snippet.thumbnails.default.url"
                alt="your-image-alt"
              />
            </b-col>
            <b-col
              cols="auto"
              class="mt-1"
              v-if="avatar.id.videoId == item.video_id"
            >
              <p>{{ avatar.snippet.title }}</p>
              <a :href="'http://youtube.com/watch?v=' + avatar.id.videoId">{{
                $t("Pages.Studio.Label.video_link")
              }}</a>
            </b-col>
          </b-row>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
/* eslint-disable global-require */
import VuexyLogo from "@core/layouts/components/Logo.vue";
import FeatherIcon from "@core/components/feather-icon/FeatherIcon.vue";
import moment from "moment";

import {
  BRow,
  BCol,
  BLink,
  BAvatar,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BCard,
  BImg,
  BFormTextarea,
  BForm,
  BButton,
  BAlert,
} from "bootstrap-vue";
import { css } from "../../../../vue.config";

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BCard,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    FeatherIcon,
    BButton,
    VuexyLogo,
    BAlert,
  },

  created() {
    this.getAllComments();
  },

  data() {
    return {
      allComments: [],
      showReplies: false,
      commentReplyText: "",
      allCommentReplies: "",
      currentItemReplies: "",
      editCommentText: "",
      uniqueVideoIds: "",
      show_alert_get_comments: true,
      avatarDetails: [],
      show_alert_get_thumbnails: true,
      pageToken: "1",
      avatarDetailsArray: [],
    };
  },

  methods: {
    getAllComments() {
      this.$http
        .get(
          `/api/channel/studio/${this.$route.params.channel_id}/comments/update-list`
        )
        .then((response) => {
          this.show_alert_get_comments = false;
          this.saveVideosAvatar(response.data[0].channel_id);
          for (let item of response.data) {
            item.replayToComment = false;
            item.showCommentReplies = false;
            item.showEditCommentSection = false;
            item.editedCommentText = "";
            this.allComments.push(item);
          }
        }).catch((error)=>{
          console.log("get comments error",error)
        });
    },
    toggleShowReplies(item) {
      item.showCommentReplies = !item.showCommentReplies;
      if (item.showCommentReplies) {
        this.getCommentsReplies(item);
      }
    },
    toggleReplayToComment(item) {
      item.replayToComment = !item.replayToComment;
    },
    insertCommentReply(item) {
      this.$http
        .post(
          `/api/channel/studio/${this.$route.params.channel_id}/${this.commentReplyText}/${item.comment_id}`
        )
        .then((response) => {
          if (response.status == 200) {
            item.replayToComment = false;
            this.makeToast(
              this.$t("Pages.Studio.Label.Done"),
              this.$t("Base.Alert.reply_success_done"),
              "success"
            );
            window.location.reload();
          }
        });
    },
    cancelReply(item) {
      item.replayToComment = !item.replayToComment;
    },
    getCommentsReplies(item) {
      this.allCommentReplies = "";
      this.currentItemReplies = item.comment_id;
      this.$http
        .post(
          `/api/channel/studio/${this.$route.params.channel_id}/${item.comment_id}/comments/replies`
        )
        .then((response) => {
          for (
            let replyItem = 0;
            replyItem < response.data.length;
            replyItem++
          ) {
            response.data[replyItem].showReplyToCommentReplyTextbox = false;
            response.data[replyItem].replyToCommentReplyText = "";
          }
          this.allCommentReplies = response.data;
        });
    },
    showEditCommentTextSection(item) {
      item.showEditCommentSection = !item.showEditCommentSection;
      item.editedCommentText = item.text;
    },
    updateEditedComment(item) {
      this.$http
        .post(
          `/api/channel/studio/${this.$route.params.channel_id}/${item.comment_id}/${item.editedCommentText}/update`
        )
        .then((response) => {
          this.makeToast(
            this.$t("Pages.Studio.Label.Done"),
            this.$t("Base.Alert.update_success_done"),
            "success"
          );
          window.location.reload();
        });
    },
    cancelEditComment(item) {
      item.showEditCommentSection = !item.showEditCommentSection;
      item.editedCommentText = "";
    },
    deleteComment(item) {
      this.$http
        .post(
          `/api/channel/studio/${this.$route.params.channel_id}/delete/${item.comment_id}/comment`
        )
        .then((response) => {
          this.makeToast(
            this.$t("Pages.Studio.Label.Done"),
            this.$t("Base.Alert.remove_success_done"),
            "success"
          );
          window.location.reload();
        });
    },
    saveVideosAvatar(data) {
      this.$http
        .get(`/api/channel/studio/${data}/thumbnail/${this.pageToken}/get`)
        .then((response) => {
          this.avatarDetails.push(response.data.items);
          if (response.data.nextPageToken) {
            this.pageToken = response.data.nextPageToken;
            this.saveVideosAvatar(response.data.items[0].snippet.channelId);
          } else {
            for (let o in this.avatarDetails) {
              for (let op in this.avatarDetails[o]) {
                this.avatarDetailsArray.push(this.avatarDetails[o][op]);
              }
            }
            this.show_alert_get_thumbnails = false;
          }
        });
    },
    toggleReplyToCommentReply(replyItem) {
      replyItem.showReplyToCommentReplyTextbox =
        !replyItem.showReplyToCommentReplyTextbox;
    },
    insertReplyToCommentReply(replyItem) {
      this.$http
        .post(
          `/api/channel/studio/${this.$route.params.channel_id}/${replyItem.replyToCommentReplyText}/${replyItem.parent_id}`
        )
        .then((response) => {
          replyItem.showReplyToCommentReplyTextbox = false;
          this.makeToast(
            this.$t("Pages.Studio.Label.Done"),
            this.$t("Base.Alert.reply_success_done"),
            "success"
          );
          window.location.reload();
        });
    },
    cancelReplyToCommentReply(replyItem) {
      replyItem.showReplyToCommentReplyTextbox =
        !replyItem.showReplyToCommentReplyTextbox;
      replyItem.replyToCommentReplyText = "";
    },
  },
};
</script>

<style scoped>
.weight-bold {
  font-weight: 900;
}
</style>
<style scoped>
.comment-counter {
  position: relative;
}
.count-number {
  width: 16px;
  height: 16px;
  position: absolute;
  background-color: #4b4b4b;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  line-height: 16px;
  font-weight: bold;
  right: 0 !important;
  left: -6px !important;
  top: -6px !important;
}
.reply-area {
  border-radius: 0.357rem;
  background-color: rgba(35, 43, 66, 0.7);
  border: 1px solid rgba(180, 183, 189, 0.2);
  color: #b4b7bd;
  outline: none;
  padding: 15px;
  width: 100%;
}
.reply-area:active,
.reply-area:focus {
  border: 1px solid rgba(219, 45, 54, 0.5);
}
.saveOrCancel {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.saveOrCancel a:first-child {
  border: 1px solid #20c997;
  padding: 5px 15px;
  border-radius: 0.375rem;
  color: #20c997;
}
.saveOrCancel a:last-child {
  border: 1px solid #db2d36;
  padding: 5px 15px;
  border-radius: 0.375rem;
  color: #db2d36;
}
.w-80 {
  width: 80%;
}
</style>
